function sliderImages(){
  return [
    {
      "src": "assets/images/image-b-1.png",
      "pillarSrc": "assets/images/img-pillar-1.png",
      "overlayImage": "assets/images/overlays/Slider_Bixal_Pillars-01.png",
      "full": 'assets/images/overlays/Slider_Bixal_Pillars_Full-01.jpg',
      "title": "A shared sense of purpose.",
      "subTitle": "We are here to positively impact the lives of people.",
      "bgColor": "#06c5bc",
      "textColor": "#55052a",
      "description": "We use human-focused strategies and cutting-edge technology to serve audiences and help our clients fulfill their missions."
    },
    {
      "src": "assets/images/image-b-2.png",
      "pillarSrc": "assets/images/img-pillar-2.png",
      "overlayImage": "assets/images/overlays/Slider_Bixal_Pillars-02.png",
      "full": 'assets/images/overlays/Slider_Bixal_Pillars_Full-02.jpg',
      "title": "A focus on Federal.",
      "subTitle": "A place where our work has the most reach.",
      "bgColor": "#dfec20",
      "textColor": "#172d6a",
      "description": "We work primarily with the Federal Government to serve large communities throughout the nation. We help clients see the challenges and possibilities within the government space and offer solutions to promote visibility and access to their organizations."
    },
    {
      "src": "assets/images/image-b-4.png",
      "pillarSrc": "assets/images/img-pillar-4.png",
      "overlayImage": "assets/images/overlays/Slider_Bixal_Pillars-04.png",
      "full": 'assets/images/overlays/Slider_Bixal_Pillars_Full-04.jpg',
      "title": "A people-first approach.",
      "subTitle": "Positive customer experiences begin with empathy.",
      "bgColor": "#55052a",
      "textColor": "#06c5bc",
      "description": "We provide a deeply nuanced understanding of real people, their expectations, their emotions, and the challenges they face. This is the power of empathy, and it guides everything we do here."
    },
    {
      "src": "assets/images/image-b-8.png",
      "pillarSrc": "assets/images/img-pillar-8.png",
      "overlayImage": "assets/images/overlays/Slider_Bixal_Pillars-08.png",
      "full": 'assets/images/overlays/Slider_Bixal_Pillars_Full-08.jpg',
      "title": "A diverse perspective.",
      "subTitle": "We come from all over.",
      "bgColor": "#bee9f6",
      "textColor": "#e02f00",
      "description": "From the top down, we are a diverse organization that embraces different ideas, perspectives, and people. Every single one of us offers a unique background and worldview that presents itself in the quality of our work."
    },
    {
      "src": "assets/images/image-b-5.png",
      "pillarSrc": "assets/images/img-pillar-5.png",
      "overlayImage": "assets/images/overlays/Slider_Bixal_Pillars-05.png",
      "full": 'assets/images/overlays/Slider_Bixal_Pillars_Full-05.jpg',
      "title": "An agile environment.",
      "subTitle": "Responding to change every step of the way.",
      "bgColor": "#162d6a",
      "textColor": "#deec1e",
      "description": "At Bixal, we develop solutions well before we start writing code. Agility is an iterative work style that values partnership, collaboration, and transparency. It shapes how we approach everything from content and marketing to human-centered design."
    },
    {
      "src": "assets/images/image-b-3.png",
      "pillarSrc": "assets/images/img-pillar-3.png",
      "overlayImage": "assets/images/overlays/Slider_Bixal_Pillars-03.png",
      "full": 'assets/images/overlays/Slider_Bixal_Pillars_Full-03.jpg',
      "title": "An outcomes mindset.",
      "subTitle": "We embrace holistic thinking.",
      "bgColor": "#7d0196",
      "textColor": "#fda305",
      "description": "The challenges of our clients span disciplines, sectors, and borders. We view everything through the lens of the customer experience and use research to drive our decision making."
    },
    {
      "src": "assets/images/image-b-7.png",
      "pillarSrc": "assets/images/img-pillar-7.png",
      "overlayImage": "assets/images/overlays/Slider_Bixal_Pillars-07.png",
      "full": 'assets/images/overlays/Slider_Bixal_Pillars_Full-07.jpg',
      "title": "An empowering culture.",
      "subTitle": "One that embraces and unites our people.",
      "bgColor": "#e02e01",
      "textColor": "#bee9f6",
      "description": "Our culture is empowered by the nature of a supportive environment. We value kindness and humility, and always seek ways to build each other up and drive ourselves all forward."
    },
    {
      "src": "assets/images/image-b-6.png",
      "pillarSrc": "assets/images/img-pillar-6.png",
      "overlayImage": "assets/images/overlays/Slider_Bixal_Pillars-06.png",
      "full": 'assets/images/overlays/Slider_Bixal_Pillars_Full-06.jpg',
      "title": "A higher standard.",
      "subTitle": "We’re not here to check boxes.",
      "bgColor": "#fda207",
      "textColor": "#7d0096",
      "description": "We set our standards high in order to exceed the expectations of our clients. You can trust us to look beyond the initial requirements and come up with solutions that can create lasting value to your organization."
    }
  ]
}

function coreServices(){
  return [
    {
      src: 'icons/services-strategy-communications.svg',
      title: 'Strategic Communications',
      bgColor: '#fda206',
      textColor: '#7d0096',
      copyColor: '#000',
      description:
        'We take pride in developing fully integrated and research-based marketing, branding, and communication strategies for our clients.',
    },
    {
      src: 'icons/services-customer-experience.svg',
      title: 'Human-Centered Design',
      bgColor: '#BEE9F6',
      textColor: '#550529',
      copyColor: '#000',
      description:
        'We want to know what propels people forward. Our approach allows us to solve real-life problems by having a clear and holistic understanding of the needs of users.',
    },
    {
      src: 'icons/services-agile.svg',
      title: 'Agile Development',
      bgColor: '#162d6a',
      textColor: '#deec1d',
      copyColor: '#fff',
      description:
        'We like to focus on small improvements before rushing to the finish line. Applying agile methodologies allows us to respond to customer reactions every step of the way.',
    },
    {
      src: 'icons/services-elearning.svg',
      title: 'Modernized Learning',
      bgColor: '#05c5bc',
      textColor: '#162d6a',
      copyColor: '#000',
      description:
        'Our human-centered approach to learning and knowledge management empowers people and organizations to make a positive impact in the workplace and in society.',
    },
    {
      src: 'icons/services-data.svg',
      title: 'Data Science and Analytics',
      bgColor: '#7d0096',
      textColor: '#fda206',
      copyColor: '#fff',
      description:
        'We offer analytical knowledge and methodologies to identify new patterns and opportunities to solve challenging problems and enable more effective decision making.',
    },
    {
      src: 'icons/services-cloud.svg',
      title: 'Cloud Computing',
      bgColor: '#550529',
      textColor: '#05c5bc',
      copyColor: '#fff',
      description:
        'A client-focused approach to successfully migrate to the cloud and modernize the technology infrastructure to improve performance and security while reducing costs.',
    },
  ]
}

module.exports = {
  sliderImages: sliderImages(),
  coreServices: coreServices()
};
