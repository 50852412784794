import React from 'react';
import Section from './Section';
import ImageSeriesHero from './ImageSeriesHero';

function RotatingImageHero({title, description}){
  return (
    <Section
      id={'rotating-image-hero'}
      bgColor={'#162d6a'}
      constrain={1400}
    >
      <div className="hero__container">
        <div className="hero__image">
          <ImageSeriesHero></ImageSeriesHero>
        </div>
        <div className="hero__text">
          <h1 className="hero__title">{ title }</h1>

          <p className="hero__description">
            { description }
          </p>
        </div>
      </div>
    </Section>
  )
}

export default RotatingImageHero;
