import React, { lazy } from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import RotatingImageHero from '../components/RotatingImageHero'
import { coreServices } from '../data/data'
import "../sass/styles.scss"

const Careers = lazy(() => import("../components/Careers"));
const WhoWeAre = lazy(() => import("../components/WhoWeAre"));
const CoreServices = lazy(() => import("../components/CoreServices"));
const ClientsAndPartners = lazy(() => import("../components/ClientsAndPartners"));
const WhatWeDo = lazy(() => import("../components/WhatWeDo"));

function IndexPage(){
  const isSSR = typeof window === 'undefined'
  return (
    <Layout>
      <Seo
        title="Home"
        description="A mission-driven organization determined to improve people’s lives through human-centered strategies and transformative technologies. We deliver on this promise by partnering with leading federal agencies to conceive and create powerful data-driven customer experiences."
        relPath={''}
      />
      <div className="page--home">
        <RotatingImageHero
          title="This is Bixal."
          description="A mission-driven organization determined to improve people’s lives through human-centered strategies and transformative technologies. We deliver on this promise by partnering with leading federal agencies to conceive and create powerful data-driven customer experiences."
        />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <WhoWeAre />
            <WhatWeDo />
            <CoreServices dataSrc={coreServices} />
            <ClientsAndPartners />
            <Careers />
          </React.Suspense>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage;
