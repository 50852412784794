import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from './Image'

function ImageSeriesHero() {
  const data = useStaticQuery(graphql`
    {
      imageSeriesHeros: allFile(
        filter: {
          relativeDirectory: { eq: "assets/images" }
          name: { regex: "/(img-b-hero)/" }
        }
      ) {
        edges {
          node {
            id
            publicURL
            relativePath
            name
          }
        }
      }
    }
  `)

  const initIndex = Math.floor(
    Math.random() * data.imageSeriesHeros.edges.length
  )
  const [currLogo, setCurrLogo] = useState({
    logoSrc: `${data.imageSeriesHeros.edges[initIndex].node.publicURL}`,
    altText:
      data.imageSeriesHeros.edges[initIndex].node.name ||
      `${data.imageSeriesHeros.edges[initIndex].node.publicURL}`,
    lazy: false,
  })

  useEffect(() => {
    let timeout = null
    let delay = 1000

    function randomize() {
      let array = [],
        dataLen = data.imageSeriesHeros.edges.length

      while (array.length < dataLen) {
        let index = Math.floor(Math.random() * dataLen)
        if (array.indexOf(index) === -1) {
          array.push(index)
        }
      }
      return array
    }

    function run(order, i) {
      let index = order[i],
        item = {
          logoSrc: `${data.imageSeriesHeros.edges[index].node.relativePath}`,
          altText:
            data.imageSeriesHeros.edges[index].node.name ||
            `${data.imageSeriesHeros.edges[index].node.relativePath}`,
          lazy: true,
        }
      setCurrLogo(item)
      i++
      if (i < order.length) {
        timeout = setTimeout(() => {
          run(order, i)
        }, delay)
      }
    }

    clearTimeout(timeout)
    setTimeout(() => {
      run(randomize(), 0)
    }, delay)
  }, [data.imageSeriesHeros.edges])


  return (
    currLogo.lazy === false ?
    <img
      src={currLogo.logoSrc}
      alt={currLogo.altText}
    /> :
    <Image
      src={currLogo.logoSrc}
      alt={currLogo.altText}
    />
  )
}

export default ImageSeriesHero
